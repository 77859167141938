@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    -webkit-font-smoothing: antialiased;
}

input[type=number] {
    -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #222222;
    transition: background-color 5000s ease-in-out 0s;
}


@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 0, 0, 13.3%;

        --card: 0 0% 100%;
        --card-foreground: 224 71.4% 4.1%;

        --popover: 0 0% 100%;
        --popover-foreground: 224 71.4% 4.1%;

        --primary: 160.588, 100%, 13%;
        --primary-foreground: 0 0% 100%;

        --secondary: 220 14.3% 95.9%;
        --secondary-foreground: 220.9 39.3% 11%;

        --muted: 220 14.3% 95.9%;
        /*--muted-foreground: 120 100.0% 50.0%;*/
        --muted-foreground: 0, 0%, 44.31%;

        --accent: 220 14.3% 95.9%;
        --accent-foreground: 220.9 39.3% 11%;

        --destructive: 10.85, 100%, 36.86%;
        --destructive-foreground: 210 20% 98%;

        --border: 220 13% 91%;
        --input: 220 13% 91%;
        --ring: rgba(255,255,255,0);

        --radius: 0.5rem;
    }

    .dark {
        --background: 224 71.4% 4.1%;
        --foreground: 210 20% 98%;

        --card: 224 71.4% 4.1%;
        --card-foreground: 210 20% 98%;

        --popover: 224 71.4% 4.1%;
        --popover-foreground: 210 20% 98%;

        --primary: 210 20% 98%;
        --primary-foreground: 220.9 39.3% 11%;

        --secondary: 215 27.9% 16.9%;
        --secondary-foreground: 210 20% 98%;

        --muted: 215 27.9% 16.9%;
        --muted-foreground: 217.9 10.6% 64.9%;

        --accent: 215 27.9% 16.9%;
        --accent-foreground: 210 20% 98%;

        --destructive: 0 62.8% 30.6%;
        --destructive-foreground: 210 20% 98%;

        --border: 215 27.9% 16.9%;
        --input: 215 27.9% 16.9%;
        --ring: 216 12.2% 83.9%;
    }
}

*{
    font-size: 16px;
    outline: none;
}

@layer base {
    * {
        @apply font-inter ;
    }

    body {
        @apply bg-background text-[#222222];
    }
}

@layer components {
    .xInput {
        @apply hover:bg-[#F5F5F5] p-[0.3rem] h-[24px] w-[24px] rounded-full cursor-pointer
    }

    .px-padding {
        @apply px-5 sm:px-6 xl:px-12
    }

    .shadow-light {
        @apply shadow-[0px_2px_24px_0px_#00000028]
    }

    .my-booked-class {
        background-color: #EBEBEB;
        color: #222222;
        border-radius: 50%;
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.box-shadow-50 {
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
}